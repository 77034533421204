



















import {
  Component, Emit, Prop, Ref, VModel, Vue,
} from 'vue-property-decorator';
import TextHeader from '@/components/partials/TextHeader.vue';
import Header from '@/assets/headers';

@Component({
  components: { TextHeader },
})
export default class TextInputArea extends Vue {
  private Header = Header;

  @Prop({ default: '' })
  private readonly label!: string;

  @Prop({ default: '' })
  private readonly placeholder!: string;

  @VModel({ default: '' })
  private input!: string;

  @Prop({ default: 'text' })
  private readonly type!: string;

  @Prop({ default: false })
  private readonly disabled!: boolean;

  @Ref('textarea')
  private textarea!: HTMLInputElement;

  @Prop({ default: () => ([]) })
  private readonly formErrors!: [];

  @Prop({ default: () => ([]) })
  private errorMessages!: string[];

  private lastHeight: number = 26;

  @Emit()
  private enterPressed() {
    // empty
  }

  private mounted() {
    this.onInput();
  }

  private get textareaClasses() {
    let classes = 'h-auto w-full overflow-hidden resize-none text-accent-grey border-b-2 focus:outline-none focus:border-accent-grey break-words';

    if (this.label && this.label.trim() !== '') {
      classes += ' mt-4';
    }

    return classes;
  }

  private onInput() {
    const { scrollY } = window;
    this.textarea.style.height = 'auto';
    this.textarea.style.height = `${this.textarea.scrollHeight}px`;

    window.scrollTo(0, scrollY);
  }
}
