



























































import { Component, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import SingleImageUpload, { UploadImageData } from '@/components/SingleImageUpload.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import TextInput from '@/components/partials/TextInput.vue';
import TextSelect from '@/components/partials/TextSelect.vue';
import TextInputArea from '@/components/partials/TextInputArea.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import Header from '@/assets/headers';
import Role from '@/assets/roles';
import Services from '@/assets/services/Services';
import { InnovamoUser } from '@/apis/userapi';
import ButtonType from '@/assets/buttonTypes';
import Page from '@/components/partials/Page.vue';

@Component({
  components: {
    SingleImageUpload,
    TextHeader,
    TextInput,
    TextSelect,
    TextInputArea,
    RoundedButtonFilled,
    LoadingAnimation,
    Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class PersonalDataView extends Vue {
    private Header = Header;
    private Role = Role;
    private ButtonType = ButtonType;
    private innovamoUser: InnovamoUser = {};
    private userRole: string|undefined = '';
    private salutationOptions: string[] = ['Herr', 'Frau', 'Divers'];

    private logoImageFile: File | null = null;
    private logoSuccessFullyUploaded: boolean = false;

    private initialLoading: boolean = true;
    private updatePersonalDataLoading: boolean = false;
    private errors: string[] = [];
    private success: boolean = false;
    private unexpectedError: boolean = false;
    private unexpectedInitialError: boolean = false;

    private async mounted() {
      try {
        const innovamoUserReponse = await Services.users.getCurrentUser();
        this.innovamoUser = innovamoUserReponse.data;
      } catch (error) {
        this.unexpectedInitialError = true;
      } finally {
        this.initialLoading = false;
      }
    }

    private async updateInnovamoUser() {
      this.updatePersonalDataLoading = true;
      this.errors = [];
      this.success = false;
      if (this.innovamoUser.password === '') {
        this.innovamoUser.password = undefined;
      }
      if (this.innovamoUser.passwordAgain === '') {
        this.innovamoUser.passwordAgain = undefined;
      }
      try {
        await Services.users.updateCurrentUser(this.innovamoUser);
        if (this.innovamoUser.firstname && this.innovamoUser.lastname) {
          const newInitials = this.innovamoUser.firstname.trim().substring(0, 1).toUpperCase() + this.innovamoUser.lastname.trim().substring(0, 1).toUpperCase();
          this.$store.commit('setUserInitials', newInitials);
        }
        this.success = true;
        this.innovamoUser.password = undefined;
        this.innovamoUser.passwordAgain = undefined;
        setTimeout(() => {
          this.success = false;
        }, 5000);
      } catch (err: unknown) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 400 && error.response.data && error.response.data.errorCodes) {
          this.errors = error.response.data.errorCodes;
        } else {
          this.unexpectedError = true;
          setTimeout(() => {
            this.unexpectedError = false;
          }, 5000);
        }
      } finally {
        this.updatePersonalDataLoading = false;
      }
    }

    private get logoImagePath(): UploadImageData {

      const imageData: UploadImageData = {
        path: '',
        filename: '',
      };

      if (this.innovamoUser.companyLogo && this.innovamoUser.companyLogo.id) {

        imageData.filename = `${this.innovamoUser.companyLogo.filename}.${this.innovamoUser.companyLogo.filetype}`;

        if (this.innovamoUser.companyLogo.isTemporaryUpload) {
          imageData.path = `${process.env.VUE_APP_USER_SERVICE_IMAGE_URL}temporary/${this.innovamoUser.companyLogo.id}`;
        } else {
          imageData.path = `${process.env.VUE_APP_USER_SERVICE_IMAGE_URL}${this.innovamoUser.companyLogo.id}`;
        }
      }

      return imageData;
    }

    private async uploadCompanyLogo(imageFile: File) {
      if (this.innovamoUser.companyLogo && this.innovamoUser.companyLogo.id !== undefined) {
        await this.removeSelectedLogoImage();
      }
      const response = await Services.users.uploadImageTemporary(imageFile);
      this.innovamoUser.companyLogo = response.data;
      this.logoSuccessFullyUploaded = true;
    }

    private async deleteCompanyLogo() {
      await this.removeSelectedLogoImage();
    }

    private async removeSelectedLogoImage() {
      this.logoImageFile = null;
      this.logoSuccessFullyUploaded = false;

      if (this.innovamoUser.companyLogo && this.innovamoUser.companyLogo.isTemporaryUpload) {
        await Services.users.deleteTemporaryImage(this.innovamoUser.companyLogo.id as number);
      } else if (this.innovamoUser.companyLogo) {
        await Services.users.deleteImage(this.innovamoUser.companyLogo.id as number);
      }

      this.innovamoUser.companyLogo = {};
    }
}
