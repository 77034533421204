


















































import { Component, Vue } from 'vue-property-decorator';
import ContentManagementTableCard from '@/components/table/ContentManagementTableCard.vue';
import Table from '@/components/table/Table.vue';
import Header from '@/assets/headers';
import { ContentPageRestDto } from '@/apis/contentpagesapi';
import Services from '@/assets/services/Services';
import TextHeader from '@/components/partials/TextHeader.vue';
import DateService from '@/assets/services/DateService';
import Page from '@/components/partials/Page.vue';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    ContentManagementTableCard, Table, TextHeader, Page, UnexpectedErrorMessage,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class ContentManagement extends Vue {
  private Header = Header;
  private showUnexpectedErrorMessage: boolean = false;
  private isLoading: boolean = true;
  private contentPages: ContentPageRestDto[] = [];

  private dateTimeFormatter = DateService.dateTimeFormatter;

  private async mounted() {
    try {
      const response = await Services.contentPages.getAllContentPages();
      this.contentPages = response.data;
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.isLoading = false;
    }
  }

  private formatDate(date: string): string {
    return `${this.dateTimeFormatter.format(new Date(date))} Uhr`;
  }

  private editPage(name: string) {
    this.$router.push({
      name: 'ContentEditor',
      params: {
        pageNameProp: name,
      },
    });
  }

}
