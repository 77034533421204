
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import { ContentPageRestDto } from '@/apis/contentpagesapi';
import Services from '@/assets/services/Services';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';
import ButtonType from '@/assets/buttonTypes';
import Page from '@/components/partials/Page.vue';

@Component({
  components: {
    BackLinkWithArrow, LoadingAnimation, RoundedButtonFilled, TextHeader, Page,
  },
  metaInfo: {
    title: 'innovaMo - digitaler Mobilitäsmarktplatz',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: '',
      },
    ],
  },
})
export default class ContentEditor extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private MARKDOWN_EDITOR_MODE_TWO_COL_LAYOUT = 'editable';
  private MARKDOWN_EDITOR_MODE_ONE_COL_LAYOUT = 'edit';

  @Prop({ required: true })
  private readonly pageNameProp!: string;

  private initialLoading: boolean = true;
  private showUnexpectedErrorMessage: boolean = false;
  private showSuccessMessage: boolean = false;
  private uploadSuccessful: boolean = false;
  private loading: boolean = false;

  private markdownEditorMode: string = this.MARKDOWN_EDITOR_MODE_TWO_COL_LAYOUT;
  private markdownEditorLeftToolbarConfig: string = 'undo redo clear | h bold italic | ul ol | link';
  private markdownEditorRightToolbarConfig: string = 'preview sync-scroll fullscreen';

  private contentPage: ContentPageRestDto = {
    name: '',
    updatedBy: '',
    updatedAt: '',
    markdownContent: '',
  };

  private async mounted() {
    try {
      const response = await Services.contentPages.getContentPageByName(this.pageNameProp);
      this.contentPage = response.data;
      this.onResize();
      window.addEventListener('resize', this.onResize);
    } catch (e) {
      this.showUnexpectedErrorMessage = true;
    } finally {
      this.initialLoading = false;
    }

  }

  private beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  private onResize() {
    this.markdownEditorMode = window.innerWidth > 640
      ? this.MARKDOWN_EDITOR_MODE_TWO_COL_LAYOUT
      : this.MARKDOWN_EDITOR_MODE_ONE_COL_LAYOUT;
  }

  private async save() {
    try {
      this.loading = true;
      const response = await Services.contentPages.updateContentPageByName(this.pageNameProp, this.contentPage);
      this.contentPage = response.data;
      this.uploadSuccessful = true;
    } catch (e) {
      console.error(e);
      this.uploadSuccessful = false;
    } finally {
      this.showSuccessMessage = true;
      this.loading = false;
    }
  }
}
