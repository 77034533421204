













import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import TextHeader from '@/components/partials/TextHeader.vue';
import Header from '@/assets/headers';

@Component({
  components: { TextHeader },
})
export default class TextSelect extends Vue {
  private Header = Header;

  @Prop({ default: '' })
  private readonly label!: string;

  @Prop({ default: '' })
  private readonly placeholder!: string;

  @VModel({ default: '' })
  private input!: string;

  @Prop({ default: [] })
  private options!: string[];

  @Emit()
  private enterPressed() {
  // empty
  }
}
