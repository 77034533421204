














import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import TableCardEntry from '@/components/table/TableCardEntry.vue';
import BaseTableCard from '@/components/table/BaseTableCard.vue';

@Component({
  components: { TableCardEntry, BaseTableCard },
})
export default class ContentManagementTableCard extends Vue {

  @Prop({ required: true })
  private name!: string;

  @Prop({ required: true })
  private updatedDate!: string;

  @Prop({ required: true })
  private updatedBy!: string;

  @Emit()
  private edit() {
    // emits event
  }
}
